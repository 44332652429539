import SectionTitle from './SectionTitle'
import Entry from './Entry'
import React from 'react'

export default function Publications() {
    return (
        <div className="">
            <SectionTitle title="PUBLICATIONS"/>
            <div className="mt-4 space-y-12">
                <Entry
                    title="Competencia Tecnológica En La Profesión De La Abogacía"
                    detail="Gerardo Cintrón Rosario, May 18, 2021"
                    subtitle="Centro de Ética Legal">
                    <a href="http://eticalegal.org/competencia-tecnologica-en-la-profesion-de-la-abogacia" target="_blank" rel="noreferrer">
                        http://eticalegal.org/competencia-tecnologica-en-la-profesion-de-la-abogacia
                    </a>
                </Entry>
            </div>
        </div>
    )
}