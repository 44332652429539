import SectionTitle from './SectionTitle'
import Entry from './Entry'
import React from 'react'

export default function Education() {
    return (
        <div className="">
            <SectionTitle title="EDUCATION"/>
            <div className="mt-4">
                <Entry
                    title="Master of Laws in Entertainment, Arts, and Sports Law"
                    detail="2022"
                    subtitle="University of Miami School of Law"
                    location="Coral Gables, FL"/>
            </div>

            <div className="mt-8">
                <Entry
                    title="Juris doctor/Master of Public Administration, magna cum laude"
                    detail="2021"
                    subtitle="University of Puerto Rico Law School"
                    location="Puerto Rico"/>

                <div>
                    <div><span className="underline">Law Journal</span>: <span className="italic">UPR Business Law Journal</span>, Editor-in-Chief</div>
                    <div><span className="underline">Honors</span>: Antonio Escudero-Viera Scholarship (Puerto Rico Community Foundation)</div>
                    <div><span className="underline">Activities</span>: Pro Bono Sports and Entertainment Law</div>
                </div>
            </div>

            <div className="mt-8">
                <Entry
                    title="Bachelor of Interdisciplinary Sciences, cum laude"
                    detail="2016"
                    subtitle="University of Puerto Rico, Rio Piedras"
                    location="Puerto Rico"/>
            </div>
        </div>
    )
}