import React from 'react'

export default function BarAdmissions() {
    return (
        <div>
            <div className="font-medium pb-3 border-b-2 border-b-white font-rubik text-white text-2xl">BAR ADMISSIONS</div>
            <div className="mt-6 space-y-8">
                <div className="font-rubik space-y-2">
                    <div className="text-xl font-medium text-white">
                        Barred in Puerto Rico (Admitted to Practice in the US)
                    </div>
                    <div className="font-normal text-gray-200">
                        Willing to obtain admission to the bar in other jurisdictions
                    </div>
                </div>
            </div>
        </div>
    )
}