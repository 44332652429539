import SectionTitle from './SectionTitle'
import React from 'react'

export default function Summary() {
    return (
        <div className="space-y-2">
            <SectionTitle title="SUMMARY"/>
            <p className="text-stone text-justify">Dynamic and accomplished Legal Professional with specialized expertise in Entertainment, Arts, and Sports Law, complemented by a strong foundation in corporate law and intellectual property. Gerardo brings a rich background in legal research, contract negotiation, and client advisory, particularly in the music industry, with notable experience handling high-profile cases and transactions. He is a bilingual communicator (English/Spanish), dedicated to delivering exceptional legal and business solutions while navigating complex regulatory environments. His career is marked by positions at prestigious law firms and involvement in impactful legal projects.</p>
        </div>
    )
}