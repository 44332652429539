import { AtSymbolIcon, PhoneIcon, HomeIcon } from '@heroicons/react/24/solid'
import React from 'react'

export default function Header() {
    return (
        <>
            <div className="font-rubik font-bold text-5xl text-stone">
                GERARDO CINTRON-ROSARIO
            </div>
            <div className="pt-2 font-inter text-light-blue text-2xl">
                Legal and Business Affairs Counsel, J.D., MPA, LL.M, Esq.
            </div>
            <div className="mt-10 lg:mt-0 flex flex-col lg:flex-row pt-4 font-rubik text-stone space-y-4 lg:space-x-10 lg:space-y-0 font-normal">
                <div className="flex items-center space-x-1">
                    <PhoneIcon className="w-4 h-4 text-light-stone"/>
                    <a href="tel:+17875646595">787-564-6595</a>
                </div>
                <div className="flex items-center space-x-1">
                    <AtSymbolIcon className="w-6 h-6 text-light-stone"/>
                    <a href="mailto:ggc31@miami.edu">ggc31@miami.edu</a>
                </div>
                <div className="flex items-center space-x-1.5">
                    <HomeIcon className="w-6 h-6 text-light-stone"/>
                    <div>Urb. Santa Juanita BA-4 c/ Guayama • Bayamon, PR 00956</div>
                </div>
            </div>
        </>
    )
}