import React from 'react'

export default function Skills() {
    return (
        <div>
            <div className="font-medium pb-3 border-b-2 border-b-white font-rubik text-white text-2xl">SKILLS</div>
            <div className="mt-6 space-y-10 font-rubik text-white">
                <div className="flex flex-col items-start space-y-2">
                    <div className="font-medium text-xl">Languages</div>
                    <div className="text-lg">
                        Spanish • English
                    </div>

                </div>
                <div className="flex flex-col items-start space-y-2">
                    <div className="font-medium text-xl">Legal Courses, Entertainment and Sports Emphasis</div>
                    <div className="text-lg">
                        NFTs Legal and Business Considerations • Entertainment & Media Law in the Digital Age • E-Sports • Law & New Technologies • Purchase and Sale of Sports Team • Negotiation and Drafting Sports Venue Agreements • Sneakers Law • Other Courses
                    </div>
                </div>
            </div>
        </div>
    )
}