import React from 'react'

export default function CommunityService() {
    return (
        <div>
            <div className="font-medium pb-3 border-b-2 border-b-white font-rubik text-white text-2xl">COMMUNITY SERVICE</div>
            <div className="mt-6 space-y-10 font-rubik text-white">
                <div className="flex flex-col items-start space-y-2">
                    <div className="font-medium text-xl">Club Me Importas Tú</div>
                    <div className="text-lg">
                        500 Hours
                    </div>

                </div>
                <div className="flex flex-col items-start space-y-2">
                    <div className="font-medium text-xl">Hurricane María / 2020 Earthquakes in Puerto Rico</div>
                    <div className="text-lg">
                        In affiliation with various organizations and groups
                    </div>
                </div>
            </div>
        </div>
    )
}