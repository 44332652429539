import SectionTitle from './SectionTitle'
import Entry from './Entry'
import React from 'react'

export default function Experience() {
    return (
        <div className="">
            <SectionTitle title="PROFESSIONAL EXPERIENCE"/>
            <div className="mt-4 space-y-12">
                <Entry
                    title="Legal Affairs Counsel"
                    detail="2022-Present; Intern, 2021-2022"
                    subtitle="Rimas Entertainment"
                    location="San Juan, PR">
                    <p className="text-justify">
                        Provided legal guidance and advice to senior management on matters related to copyright law, trademark law, and music licensing. Conducted legal research regarding intellectual property infringement. Negotiated and finalized contracts with major record labels and music distributors. Conducted due diligence on potential business partners and clients, including reviewing and analyzing contracts, financial statements, and legal documents. Drafted agreements for Bad Bunny’s 2022 tours (1) “El Ultimo Tour Del Mundo” and (2) “World’s Hottest Tour.” Drafted and reviewed licensing agreements for use of music in film, television, and commercials. Managed legal aspects of sponsorship and endorsement deals for artists and record label. Drafted producer agreements for master recordings, as well as guest artist live performance agreements and master service agreement forms for projects regarding the Record Label’s artists. Drafted marketing and advertising agreements for
                        various artists including Bad Bunny, Mora, Eladio Carrión and Jowell & Randy. Advised clients on compliance with federal and state regulations related to the music industry, including labor and employment laws, consumer protection laws, and advertising regulations..
                    </p>
                </Entry>

                <Entry
                    title="Corporate Associate"
                    detail="2022-2022"
                    subtitle="Oneill & Borges, LLC"
                    location="San Juan, PR">
                    <p className="text-justify">
                        Worked on cases involving Intellectual Property, mergers and acquisitions, real estate, transportation and ports, government affairs and the sports gambling industry. Performed various assignments with issues involving The Puerto Rico Oversight, Management, and Economic Stability Act and reorganization of entities and conducted due diligence for various transactions. Drafted contracts and agreements for purchasing and selling various real estate in Puerto Rico, as well as contracts in other practice areas such as government affairs, transportation and ports, and sports gambling industry. Prepared legal memoranda regarding political hearings, mergers and acquisitions and purchase of sales agreements. Participated in client negotiations and transactions.
                    </p>
                </Entry>

                <Entry
                    title="Research Analyst"
                    detail="2021-2022"
                    subtitle="Odds on Compliance"
                    location="Miami, FL">
                    <p className="text-justify">
                        Conducted legal research regarding sports betting laws in different jurisdictions of the United States of America. Reviewed and analyzed documents, statutes, and regulations regarding sports betting laws.
                    </p>
                </Entry>

                <Entry
                    title="Law Clerk"
                    detail="2020 - 2021; 2018 - 2019"
                    subtitle="Córdova & Dick, LLC"
                    location="San Juan, PR">
                    <p className="text-justify">
                        Conducted legal research regarding civil code provisions, statutes and case law. Drafted and revised motions to dismiss appeals, motions for a new trial and interrogatory questions. Prepared memoranda of law concerning rules of civil procedure, trusts and estates, landlord-tenant agreements and other matters. Communicated with clients regarding strategies, discovery and status of cases. Assisted with drafting motions for summary judgement.</p>
                </Entry>

                <Entry
                    title="Law Clerk"
                    detail="2019 – 2020; Summer 2020; Summer 2019"
                    subtitle="McConnell Valdés"
                    location="San Juan, PR">
                    <p className="text-justify">
                        Worked closely with a senior partner on cases involving Intellectual Property, mergers and acquisitions, international banking, government affairs and the cannabis industry. Conducted legal research for a large chain supermarket regarding the Supplemental Nutrition Assistance Program and food and grocery deliveries. Performed extensive legal research concerning diverse issues involving The Puerto Rico Oversight, Management, and Economic Stability Act, the cannabis industry and reorganization of entities. Drafted contracts and agreements for the purchase of sale of various real estate in Puerto Rico, as well as memoranda of law concerning Puerto Rico’s General Corporation Act. Assisted attorneys with written documents and summaries in preparation for hearings. Prepared legal memoranda regarding procedural issues, as well as substantive matters involving trusts and estates, mergers and acquisitions and purchase of sales agreements. Participated in client
                        negotiations and transactions. Worked closely with the Labor Department and drafted company policies and employee handbooks for certain clients.
                    </p>
                </Entry>

                <Entry
                    title="English Teacher"
                    detail="2016-2017"
                    subtitle="Department of Education"
                    location="San Juan, PR">
                    <p className="text-justify">
                        Taught students from kindergarten through third grade and guided them to successfully complete studies. Completed weekly planning for the students. Attended staff meetings regarding monthly activities and deadlines.
                    </p>
                </Entry>
            </div>
        </div>
    )
}